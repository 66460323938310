<template>
  <section class="transfer">
    <h2 class="heading">{{ billCategory.name }}</h2>
    <div
      class="trasactions__container"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <section
        v-for="category in categories"
        :key="category.billerid"
        class="category"
        @click="showSubCategories(category)"
      >
        <div class="category__left">
          <h2 class="category__name">{{ category.billername }}</h2>
          <h3 class="category__type">{{ category.narration }}</h3>
        </div>
        <div class="category__right">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            focusable="false"
            style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M5.536 21.886a1.004 1.004 0 0 0 1.033-.064l13-9a1 1 0 0 0 0-1.644l-13-9A1 1 0 0 0 5 3v18a1 1 0 0 0 .536.886z"
              fill="#db353a"
            />
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import api from "@/api/api.js";

import { mapState } from "vuex";
export default {
  computed: mapState(["userAccounts", "user", "billCategory"]),
  data() {
    return {
      loading: false,
      categories: []
    };
  },
  methods: {
    showSubCategories(details) {
      let payload = {
        billerId: details.billerid
      };

      this.loading = true;

      api
        .getBillerPayItems(payload)
        .then(response => {
          this.loading = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let subCategories = response.Data;

            if (subCategories <= 0) {
              this.$message({
                showClose: true,
                message: `No records found.`,
                type: "error",
                duration: 5000
              });
              return;
            }

            this.$store.dispatch("setCategoryTwoDetails", details);
            this.$store.dispatch("setSubCategoryDetails", subCategories);
            this.$router.push("/dashboard/bills/pay-biller");
          } else {
            this.$message({
              showClose: true,
              message: `${responseMessage.charAt(0).toUpperCase() +
                responseMessage.slice(1)}`,
              type: "error",
              duration: 10000
            });
          }
        })
        .catch(error => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000
          });
        });
    }
  },
  mounted() {
    let payload = {
      categoryId: this.billCategory.categoryid
    };

    this.loading = true;

    api
      .getBillerByCategoryId(payload)
      .then(response => {
        this.loading = false;
        let responseStatus = response.Status;
        let responseMessage = response.Message;

        if (responseStatus) {
          let categoriesList = response.Data;

          if (categoriesList <= 0) {
            this.$message({
              showClose: true,
              message: `No records found.`,
              type: "error",
              duration: 5000
            });
          }

          this.categories = categoriesList;
        } else {
          this.$message({
            showClose: true,
            message: `${responseMessage.charAt(0).toUpperCase() +
              responseMessage.slice(1)}`,
            type: "error",
            duration: 10000
          });
        }
      })
      .catch(error => {
        this.loading = false;
        this.$message({
          showClose: true,
          message: `${error}`,
          type: "error",
          duration: 10000
        });
      });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
